import React, { useEffect, useState } from "react";
import TablePosts from "./components/TablePosts";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function Posts() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `/api_w/postcategories`,
          {
            headers: {
              db_name: process.env.REACT_APP_DB_NAME,
            },
          }
        );
        setCategories(response.data.data);
      } catch (error) {
        toast.error(
          "Error fetching kategori: " +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleTambahClick = () => {
    navigate("/admin/add-posts");
  };

  const handleKategoriClick = () => {
    navigate("/admin/categories");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <button
            className="mr-2 rounded bg-orange-500 px-4 py-2 text-white"
            onClick={handleTambahClick}
          >
            Tambah Postingan
          </button>
          <button
            className="mt-4 rounded bg-yellow-500 px-4 py-2 text-white"
            onClick={handleKategoriClick}
          >
            Lihat Kategori
          </button>
        </div>
        <div className="flex items-center">
          <span className="mr-2">Filter by Kategori:</span>
          <select
            className="rounded bg-white px-2 py-2"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="">Semua</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <TablePosts selectedCategory={selectedCategory} />
    </div>
  );
}

export default Posts;
