import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const AddFoto = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [imageUrls, setImageUrls] = useState([]);

  const handleCancel = () => {
    navigate("/admin/foto");
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const url = [];

    if (files.length > 0) {
      try {
        for (const file of files) {
          const formData = new FormData();
          formData.append("file", file);

          const response = await axios.post(
            `/api_w/upload/multiple`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                db_name: process.env.REACT_APP_DB_NAME,
              },
            }
          );

          const uploadedImageUrl = response.data.data[0].Location;
          url.push(uploadedImageUrl);
        }

        setImageUrls(url);
      } catch (error) {
        toast.error(
          "Error Upload Gambar: " +
            (error.response?.data?.message || error.message)
        );
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `/api_w/galleries`,
        {
          title,
          url: imageUrls,
          type: "image",
        },
        {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      console.log(response.data.data);
      toast.success("Berhasil Tambah Foto!");
      navigate("/admin/foto");
    } catch (error) {
      toast.error(
        "Error Tambah Foto: " + (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <div className="p-5">
      <div className="mb-4">
        <label className="text-md mb-2 block font-bold text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          id="title"
          name="title"
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          placeholder="Judul"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-bold text-gray-800 dark:text-white">
          Gambar
        </label>
        {imageUrls.length > 0 && (
          <div className="mt-2 flex gap-4 dark:text-white">
            {imageUrls.map((url, index) => (
              <img
                key={index}
                src={url}
                alt="title"
                className="h-24 w-36 rounded-md"
              />
            ))}
          </div>
        )}
        <input
          type="file"
          id="img"
          name="img"
          accept="image/*"
          multiple
          className="w-full rounded border px-3 py-2 dark:text-white"
          onChange={handleFileChange}
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 5MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 py-2 px-4 font-bold text-white"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="button"
          className="rounded bg-blue-600 py-2 px-4 font-bold text-white"
          onClick={handleSubmit}
        >
          Tambah
        </button>
      </div>
    </div>
  );
};

export default AddFoto;
