import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import parse from "html-react-parser";

function ListBerita() {
  const [posts, setPosts] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`/api_w/posts`, {
          params: {
            // status: 1,
            category_id: 1,
          },
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const filteredPosts = response.data.data.filter(
          (post) => post.status == 1
        );

        const sortedPosts = filteredPosts.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setPosts(sortedPosts);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    // const fetchCategories = async () => {
    //   try {
    //     const response = await axios.get(`/api_w/postcategories`, {
    //       headers: {
    //         db_name: process.env.REACT_APP_DB_NAME,
    //       },
    //     });
    //     setCategories(response.data.data);
    //   } catch (error) {
    //     toast.error(error.response?.data?.message || error.message);
    //   }
    // };

    fetchPosts();
    // fetchCategories();
  }, []);

  // const handleCategoryFilter = (categoryId) => {
  //   setSelectedCategory(categoryId);
  // };

  // const sortedPosts = posts.sort((a, b) => new Date(b.date) - new Date(a.date));

  // const filteredPosts = selectedCategory
  //   ? sortedPosts.filter(
  //       (post) => post.category_id === parseInt(selectedCategory)
  //     )
  //   : sortedPosts;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="mb-8">
      <h3 className="headers text-xl">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          Berita
        </span>
      </h3>
      <div className="mt-5">
        {/* <div className="mb-4 flex flex-wrap">
          <button
            onClick={() => handleCategoryFilter("")}
            className={`mr-2 mb-2 rounded-md border py-1 px-2 ${
              selectedCategory === ""
                ? "border-2 border-blue-600 bg-blue-600 text-white"
                : "border-2 border-gray-300 text-gray-700"
            }`}
          >
            Semua
          </button>
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => handleCategoryFilter(category.id)}
              className={`mr-2 mb-2 rounded-md border py-1 px-2 ${
                category.id === selectedCategory
                  ? "border-2 border-blue-600 bg-blue-600 text-white"
                  : "border-2 border-gray-300 text-gray-700"
              }`}
            >
              {category.name}
            </button>
          ))}
        </div> */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
          {posts.map((post) => (
            <div
              key={post.id}
              className="flex flex-col justify-between rounded-md shadow-lg"
            >
              <div>
                <div className="relative flex h-[200px] items-center justify-center overflow-hidden rounded-t-md">
                  <img
                    className="w-full transform object-cover transition-transform duration-300 hover:scale-110"
                    src={post.image}
                    alt={post.title}
                  />
                  <div className="absolute left-0 bottom-0 bg-blue-500 px-2 py-1 text-white rounded-tr-md">
                    <p className="text-xs">{formatDate(post.date)}</p>
                  </div>
                </div>
                <div className="flex-grow p-4">
                  <Link to={`/berita/${post.slug}`}>
                    <div className="mb-2 text-xl font-bold hover:text-blue-700 text-justify">
                      {truncateText(post.title, 50)}
                    </div>
                  </Link>
                  <p className="text-justify text-base text-gray-700">
                    {parse(truncateText(post.description, 200))}
                  </p>
                </div>
              </div>
              <div className="p-4">
                <Link
                  to={`/berita/${post.slug}`}
                  className="inline-block rounded-lg bg-blue-500 px-2 py-2 text-center text-sm font-bold text-white hover:bg-blue-600"
                >
                  Baca Selengkapnya
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListBerita;
