import React from "react";

const InputFields = ({ label, name, value, onChange, readOnly }) => {
  return (
    <div className="mb-4">
      <label className="text-md block font-medium font-bold text-gray-800">
        {label}
      </label>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        className={`block w-full rounded-md border-2 px-3 py-2 shadow-sm outline-none ${
          readOnly ? "border-transparent bg-gray-100" : "border-gray-300"
        }`}
      />
    </div>
  );
};

export default InputFields;