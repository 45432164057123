import React from "react";
import Dashboard from "./views/admin/dashboard";
import {
  MdArticle,
  MdPerson,
  MdDashboard,
  MdImage,
  MdVideoLibrary,
  MdViewCarousel,
  MdMenuBook,
} from "react-icons/md";
import { RiImageCircleFill } from "react-icons/ri";
import Carousel from "./views/admin/carousel";
import AddCarousel from "./views/admin/carousel/components/AddCarousel";
import EditCarousel from "./views/admin/carousel/components/EditCarousel";
import Posts from "./views/admin/posts";
import AddPost from "./views/admin/posts/components/AddPosts";
import Categories from "./views/admin/posts/categories";
import EditPost from "./views/admin/posts/components/EditPosts";
import Logo from "./views/admin/logo";
import AddLogo from "./views/admin/logo/components/AddLogo";
import EditLogo from "./views/admin/logo/components/EditLogo";
import Foto from "./views/admin/foto";
import AddFoto from "./views/admin/foto/components/AddFoto";
import EditFoto from "./views/admin/foto/components/EditFoto";
import Video from "./views/admin/video";
import AddVideo from "./views/admin/video/components/AddVideo";
import EditVideo from "./views/admin/video/components/EditVideo";
import MediaSosial from "./views/admin/medsos";
import VisiMisi from "./views/admin/visi-misi";
import EditVisiMisi from "./views/admin/visi-misi/components/EditVisiMisi";
import Sambutan from "./views/admin/sambutan";
import EditSambutan from "./views/admin/sambutan/components/EditSambutan";
import User from "./views/admin/users";
import AddUser from "./views/admin/users/components/AddUser";
import EditUser from "./views/admin/users/components/EditUser";
import { FaComment } from "react-icons/fa";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <Dashboard />,
  },
];

const konten = [
  {
    name: "Gambar Logo",
    layout: "/admin",
    path: "logo",
    icon: <RiImageCircleFill className="h-6 w-6"/>,
    component: <Logo />,
  },
  {
    name: "Add Logo",
    layout: "/admin",
    path: "add-logo",
    hidden: true,
    component: <AddLogo />,
  },
  {
    name: "Edit Logo",
    layout: "/admin",
    path: "edit-logo/:id",
    hidden: true,
    component: <EditLogo />,
  },
  {
    name: "Carousel",
    layout: "/admin",
    path: "carousel",
    icon: <MdViewCarousel className="h-6 w-6" />,
    component: <Carousel />,
  },
  {
    name: "Add Carousel",
    layout: "/admin",
    path: "add-carousel",
    hidden: true,
    component: <AddCarousel />,
  },
  {
    name: "Edit Carousel",
    layout: "/admin",
    path: "edit-carousel/:id",
    hidden: true,
    component: <EditCarousel />,
  },
  {
    name: "Media Sosial",
    layout: "/admin",
    path: "medsos",
    icon: <FaComment className="h-6 w-6"/>,
    component: <MediaSosial/>
  },
  {
    name: "Postingan",
    layout: "/admin",
    icon: <MdArticle className="h-6 w-6" />,
    path: "posts",
    component: <Posts />,
  },
  {
    name: "Add Postingan",
    layout: "/admin",
    path: "add-posts",
    hidden: true,
    component: <AddPost />,
  },
  {
    name: "Edit Postingan",
    layout: "/admin",
    path: "edit-posts/:id",
    hidden: true,
    component: <EditPost />,
  },
  {
    name: "Kategori Postingan",
    layout: "/admin",
    path: "categories",
    hidden: true,
    component: <Categories />,
  },
  {
    name: "Visi Misi",
    layout: "/admin",
    path: "visi-misi",
    icon: <MdMenuBook className="h-6 w-6" />,
    component: <VisiMisi />,
  },
  {
    name: "Edit Visi",
    layout: "/admin",
    path: "edit-visi-misi/:id",
    hidden: true,
    component: <EditVisiMisi />,
  },
  {
    name: "Sambutan",
    layout: "/admin",
    path: "sambutan",
    icon: <MdMenuBook className="h-6 w-6" />,
    component: <Sambutan />,
  },
  {
    name: "Edit Sambutan",
    layout: "/admin",
    path: "edit-sambutan/:id",
    hidden: true,
    component: <EditSambutan />,
  },
];

const media = [
  {
    name: "Galeri Foto",
    layout: "/admin",
    icon: <MdImage className="h-6 w-6" />,
    path: "foto",
    component: <Foto />,
  },
  {
    name: "Add Media Foto",
    layout: "/admin",
    path: "add-foto",
    hidden: true,
    component: <AddFoto />,
  },
  {
    name: "Edit Media Foto",
    layout: "/admin",
    path: "edit-foto/:id",
    hidden: true,
    component: <EditFoto />,
  },

  {
    name: "Galeri Video",
    layout: "/admin",
    icon: <MdVideoLibrary className="h-6 w-6" />,
    path: "video",
    component: <Video />,
  },
 
  {
    name: "Add Media Video",
    layout: "/admin",
    path: "add-video",
    hidden: true,
    component: <AddVideo />,
  },
  {
    name: "Edit Media Video",
    layout: "/admin",
    path: "edit-video/:id",
    hidden: true,
    component: <EditVideo />,
  },
];

const pengaturan = [
  {
    name: "User",
    layout: "/admin",
    path: "user",
    icon: <MdPerson className="h-6 w-6" />,
    component: <User />,
  },
  {
    name: "Tambah User",
    layout: "/admin",
    path: "add-user",
    hidden: true,
    component: <AddUser />,
  },
  {
    name: "Edit User",
    layout: "/admin",
    path: "edit-user/:id",
    hidden: true,
    component: <EditUser />,
  },
];

export default routes;
export { konten };
export { media };
export { pengaturan };
