import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Tabs from "./Tabs";

function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dataRegister, setDataRegister] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    phone: "",
    profile_pict: "",
    role: "",
  });

  useEffect(() => {
    const fetchUserById = async () => {
      try {
        const response = await axios.get(`/api_w/users/${id}`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const usersData = response.data.data;
        setDataRegister({
          name: usersData.name,
          username: usersData.username,
          email: usersData.email,
          password: usersData.password,
          phone: usersData.phone,
          profile_pict: usersData.profile_pict,
          role: usersData.role,
        });
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchUserById();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataRegister((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post("/api_w/upload/single", formData, {
        headers: {
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      setDataRegister((prevData) => ({
        ...prevData,
        profile_pict: response.data.data.Location,
      }));
    } catch (error) {
      toast.error(
        "Error Upload Gambar: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  //Update Users
  const handleRegister = async (e) => {
    e.preventDefault();

    const config = {
      method: "put",
      url: `/api_w/users/updateuser?user_id=${id}`,
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        db_name: process.env.REACT_APP_DB_NAME,
      },
      data: JSON.stringify({ ...dataRegister }),
    };

    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil Edit User!");
      navigate("/admin/user");
    } catch (error) {
      toast.error(
        "Error Edit User" + (error.response?.data?.message || error.message)
      );
    }
  };

  //change password
  const [dataChangePassword, setDataChangePassword] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordValidate: "",
  });

  const handleChangeUbahPassword = (e) => {
    const { name, value } = e.target;
    setDataChangePassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (
      dataChangePassword.newPassword !== dataChangePassword.newPasswordValidate
    ) {
      toast.error("Password baru dan konfirmasi password tidak cocok.");
      return;
    }
    try {
      const postDataChangePass = {
        ...dataChangePassword,
      };
      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: "/api_w/users/changepassword",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postDataChangePass),
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil Ubah Password!");
      navigate("/admin/user");
    } catch (error) {
      toast.error(
        "Error Ubah Password: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  //reset password
  const [dataResetPassword, setDataResetPassword] = useState({
    newPassword: "",
  });

  const handleChangeResetPassword = (e) => {
    const { name, value } = e.target;
    setDataResetPassword((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const postDataResetPass = {
        ...dataResetPassword,
      };
      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: "/api_w/users/resetpassword",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postDataResetPass),
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil reset Password!");
      navigate("/admin/user");
    } catch (error) {
      toast.error(
        "Error Reset Password: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleCancel = () => {
    navigate("/admin/user");
  };

  const tabsData = [
    {
      label: "REGISTRASI",
      content: (
        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
              Foto Profil
            </label>
            <img
              alt=""
              src={dataRegister.profile_pict}
              className="h-auto w-auto"
            />
            <input
              type="file"
              name="profil_pict"
              onChange={handleFileChange}
              className="w-full rounded-md px-2 py-2 dark:text-white"
            />
            <span className="ml-1 mt-0 block text-xs text-gray-600">
              *Max size: 5MB, Format: .png, .jpg, .jpeg, .svg, .webp
            </span>
          </div>
          <div className="mb-4">
            <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={dataRegister.name}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
              required
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="mb-4">
              <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
                Username
              </label>
              <input
                type="text"
                name="username"
                value={dataRegister.username}
                onChange={handleChange}
                className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
                required
              />
            </div>
            <div className="mx-auto mb-4">
              <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
                Email
              </label>
              <input
                type="text"
                name="email"
                value={dataRegister.email}
                onChange={handleChange}
                className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={dataRegister.password}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
              required
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
              Phone Number
            </label>
            <input
              type="text"
              name="phone"
              value={dataRegister.phone}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
              Role
            </label>
            <select
              name="role"
              value={dataRegister.role}
              onChange={handleChange}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
              required
            >
              <option value="" disabled>
                Pilih Kategori
              </option>
              <option value="superadmin">superadmin</option>
              <option value="admin">admin</option>
              <option value="user">user</option>
            </select>
          </div>
          <div className="flex justify-end gap-4">
            <button
              onClick={handleCancel}
              type="button"
              className="rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700"
            >
              Batal
            </button>
            <button
              type="submit"
              className="rounded bg-orange-500 py-2 px-4 font-bold text-white hover:bg-orange-500"
            >
              Edit User
            </button>
          </div>
        </form>
      ),
    },
    {
      label: "UBAH PASSWORD",
      content: (
        <form onSubmit={handleChangePassword}>
          <div className="mb-4">
            <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
              Password Lama
            </label>
            <input
              type="password"
              name="oldPassword"
              value={dataChangePassword.oldPassword}
              onChange={handleChangeUbahPassword}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
              Password Baru
            </label>
            <input
              type="password"
              name="newPassword"
              value={dataChangePassword.newPassword}
              onChange={handleChangeUbahPassword}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
              Konfirmasi Password Baru
            </label>
            <input
              type="password"
              name="newPasswordValidate"
              value={dataChangePassword.newPasswordValidate}
              onChange={handleChangeUbahPassword}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={handleCancel}
              className="rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700"
            >
              Batal
            </button>
            <button
              type="submit"
              className="rounded bg-orange-500 py-2 px-4 font-bold text-white hover:bg-orange-500"
            >
              Ubah Password
            </button>
          </div>
        </form>
      ),
    },
    {
      label: "PASSWORD RESET",
      content: (
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
              Password Baru
            </label>
            <input
              type="password"
              name="newPassword"
              value={dataResetPassword.newPassword}
              onChange={handleChangeResetPassword}
              className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={handleCancel}
              className="rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700"
            >
              Batal
            </button>
            <button
              type="submit"
              className="rounded bg-orange-500 py-2 px-4 font-bold text-white hover:bg-orange-500"
            >
              Reset Password
            </button>
          </div>
        </form>
      ),
    },
  ];

  return (
    <div className="p-4">
      <Tabs tabs={tabsData} />
    </div>
  );
}

export default EditUser;
