import React, { useState } from "react";
import { MdArrowDropDown, MdCollections } from "react-icons/md";
import { konten } from "../../../routes";
import Links from "./Links";
import { media } from "../../../routes";
import { pengaturan } from "../../../routes";

function Menu() {
  const [showMediaDropdown, setShowMediaDropdown] = useState(false);

  const handleMediaClick = () => {
    setShowMediaDropdown(!showMediaDropdown);
  };

  return (
    <>
      <span className="flex items-center py-3 pl-8 text-base font-medium text-gray-500">
        Manajemen Konten
      </span>
      <Links routes={konten} />
      <li
        className="flex cursor-pointer items-center py-3 pl-8 text-base font-medium text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-white/10"
        onClick={handleMediaClick}
      >
        <MdCollections className="mr-4 h-6 w-6" />
        Media
        <MdArrowDropDown className="ml-auto h-6 w-6" />
      </li>
      {showMediaDropdown && (
        <div className="pl-10">
          <Links routes={media} />
        </div>
      )}
      <span className="flex items-center py-3 pl-8 text-base font-medium text-gray-500">
        Pengaturan
      </span>
      <Links routes={pengaturan} />
    </>
  );
}

export default Menu;
