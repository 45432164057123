import React from "react";
import { FaFacebookSquare, FaTelegram } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { FaSquareXTwitter } from "react-icons/fa6";

function ShareSosmed() {
  const handleWhatsAppShare = () => {
    const url = window.location.href;
    const text = `${url}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleFacebookShare = () => {
    const url = window.location.href;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    window.open(facebookUrl, "_blank");
  };

  const handleTwitterShare = () => {
    const url = window.location.href;
    const text = `${url}`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}`;
    window.open(twitterUrl, "_blank");
  };

  const handleTelegramShare = () => {
    const url = window.location.href;
    const text = `${url}`;
    const telegramUrl = `https://telegram.me/share/url?url=${encodeURIComponent(
      url
    )}&text=${encodeURIComponent(text)}`;
    window.open(telegramUrl, "_blank");
  };

  return (
    <div className="mt-4 flex flex-col items-center justify-center">
      <div className="mb-2 text-lg font-bold">Bagikan artikel:</div>
      <div className="flex items-center justify-center gap-2">
        <RiWhatsappFill
          className="mr-2 h-7 w-7 cursor-pointer text-blue-500 hover:text-blue-600"
          onClick={handleWhatsAppShare}
        />
        <FaFacebookSquare
          className="mr-2 h-7 w-7 cursor-pointer text-blue-600 hover:text-blue-700"
          onClick={handleFacebookShare}
        />
        <FaSquareXTwitter
          className="text-black-500 mr-2 h-7 w-7 cursor-pointer"
          onClick={handleTwitterShare}
        />
        <FaTelegram
          className="mr-2 h-7 w-7 cursor-pointer text-blue-500 hover:text-blue-600"
          onClick={handleTelegramShare}
        />
      </div>
    </div>
  );
}

export default ShareSosmed;
