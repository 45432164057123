import React, { useState, useEffect } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddPost = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    date: "",
    category_id: "",
    status: "",
  });
  const [categories, setCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `/api_w/postcategories`,
          {
            headers: {
              db_name: process.env.REACT_APP_DB_NAME,
            },
          }
        );
        setCategories(response.data.data);
      } catch (error) {
        toast.error(
          "Error fetching kategori: " +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchCategories();
  }, []);

  const handleCancel = () => {
    navigate("/admin/posts");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const data = new FormData();
    data.append("file", selectedFile);

    try {
      const response = await axios.post(
        `/api_w/upload/single`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );

      return response.data.data.Location;
    } catch (error) {
      toast.error(
        "Error Upload Gambar: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const imageURL = await handleFileUpload();

      const postData = {
        ...formData,
        image: imageURL,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api_w/posts`,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          db_name: process.env.REACT_APP_DB_NAME,
        },
        data: JSON.stringify(postData),
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil Tambah Postingan!");
      navigate("/admin/posts");
    } catch (error) {
      console.log(error.response)
      toast.error(
        "Error Tambah Postingan: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Judul
        </label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Deskripsi
        </label>
        <CKEditor
          editor={ClassicEditor}
          data={formData.description}
          onChange={handleDescriptionChange}
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Gambar
        </label>
        {selectedFile && (
          <img
            src={URL.createObjectURL(selectedFile)}
            alt="Preview"
            className="ml-4 h-auto w-auto"
          />
        )}
        <input
          type="file"
          name="image"
          onChange={handleFileChange}
          className="w-full rounded-md px-2 py-2 dark:text-white"
        />
        <span className="ml-1 mt-0 block text-xs text-gray-600">
          *Max size: 2MB, Format: .png, .jpg, .jpeg, .svg, .webp
        </span>
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Tanggal
        </label>
        <input
          type="datetime-local"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
        />
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Kategori
        </label>
        <select
          name="category_id"
          value={formData.category_id}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        >
          <option value="" disabled>
            Pilih Kategori
          </option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="text-md block font-medium font-bold text-gray-800 dark:text-white">
          Status
        </label>
        <select
          name="status"
          value={formData.status}
          onChange={handleChange}
          className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
          required
        >
          <option value="" disabled>
            Pilih Status
          </option>
          <option value="true">active</option>
          <option value="false">non active</option>
        </select>
      </div>
      <div className="flex justify-end gap-4">
        <button
          type="button"
          className="rounded bg-red-500 py-2 px-4 font-bold text-white"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          type="submit"
          className="rounded bg-blue-600 py-2 px-4 font-bold text-white"
        >
          Tambah
        </button>
      </div>
    </form>
  );
};

export default AddPost;
