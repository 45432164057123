import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";
import { toast } from "react-toastify";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100vw",
  height: "100vh",
};

const defaultCenter = {
  lat: -7.797068,
  lng: 110.379558,
};

const Maps = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD0EuEBPjS92sMND4h2cp_j5mGPFzjdtpE",
    libraries,
  });

  const [contact, setContact] = useState({
    school_name: "",
    address: "",
    link_maps: "",
    coordinates: "",
  });

  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    const fetchKontak = async () => {
      try {
        const response = await axios.get(`/api_w/medsos`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const kontak = response.data.data[0];
        setContact(kontak || {}); // Ensure contact is set to an object even if undefined
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };
    fetchKontak();
  }, []);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  let coordinates = defaultCenter;
  if (contact && contact.coordinates) {
    const [lat, lng] = contact.coordinates.split(",").map(Number);
    coordinates = { lat, lng };
  }

  const handleMarkerClick = () => {
    setShowInfoWindow(true);
  };

  const handleInfoWindowClose = () => {
    setShowInfoWindow(false);
  };

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={coordinates}
        options={{
          zoomControl: true,
        }}
      >
        <Marker position={coordinates} onClick={handleMarkerClick} />
        {showInfoWindow && (
          <InfoWindow position={coordinates} onClick={handleInfoWindowClose}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "justify",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "left",
                }}
              >
                <div
                  className="text-left font-bold"
                  style={{ marginRight: "auto" }}
                >
                  {contact.school_name || "Jogja Madrasah Digital"}
                </div>
              </div>
              <br />
              <span
                className="w-40"
                style={{ display: "inline-block", textOverflow: "ellipsis" }}
              >
                {contact.address ||
                  "Jl. Sukonandi No.8, Semaki, Kec. Umbulharjo, Kota Yogyakarta, Daerah Istimewa Yogyakarta, 55166"}
              </span>
              <br />
              <a
                href={
                  contact.link_maps ||
                  "https://maps.app.goo.gl/tZnXmodv7gHNoG9V9"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                View on Google Maps
              </a>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
};

export default Maps;
