import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function BeritaPopuler() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const response = await axios.get(`/api_w/posts`, {
          params: {
            // status: 1,
            category_id: 1,
          },
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const filteredPosts = response.data.data.filter(
          (post) => post.status == 1
        );

        const sortedCards = filteredPosts.sort(
          (a, b) => new Date(b.views) - new Date(a.views)
        );
        setCards(sortedCards.slice(0, 4));
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchPostsData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const truncateTitle = (title, limit) => {
    if (title.length > limit) {
      return title.substring(0, limit) + "...";
    } else {
      return title;
    }
  };

  return (
    <div>
      <h3 className="headers text-xl">
        <span className="border-b-2 border-blue-500 font-bold font-bold hover:cursor-pointer hover:text-blue-700">
          Berita Populer
        </span>
      </h3>
      <div className="mt-4 flex flex-col">
        {cards.slice(0, 3).map((news) => (
          <Link
            key={news.id}
            to={`/berita/${news.slug}`}
            className="mb-4 flex overflow-hidden rounded-lg border shadow-md hover:shadow-lg"
          >
            <img src={news.image} alt="berita" className="h-auto w-36" />
            <div className="flex flex-col justify-between p-3">
              <h4 className="mb-2 text-lg font-semibold">
                {truncateTitle(news.title, 35)}
              </h4>
              <p className="text-sm text-gray-500">{formatDate(news.date)}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BeritaPopuler;
