import React, { useState } from "react";

const cards = [
  {
    id: 1,
    title: "Card 1",
    description: "Lorem ipsum dolor sit amet.",
    image: "https://via.placeholder.com/150",
    category: "Berita",
  },
  {
    id: 2,
    title: "Card 2",
    description: "Consectetur adipiscing elit.",
    image: "https://via.placeholder.com/150",
    category: "Pengumuman",
  },
  {
    id: 3,
    title: "Card 3",
    description: "Sed do eiusmod tempor incididunt.",
    image: "https://via.placeholder.com/150",
    category: "Agenda",
  },
  {
    id: 4,
    title: "Card 4",
    description: "Ut labore et dolore magna aliqua.",
    image: "https://via.placeholder.com/150",
    category: "ZI",
  },
  {
    id: 5,
    title: "Card 5",
    description: "Ut labore et dolore magna aliqua.",
    image: "https://via.placeholder.com/150",
    category: "Berita",
  },
];

const categories = ["Semua", "ZI", "Berita", "Agenda", "Pengumuman"];

function Media() {
  const [selectedCategory, setSelectedCategory] = useState("Semua");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const filteredCards =
    selectedCategory === "Semua"
      ? cards
      : cards.filter((card) => card.category === selectedCategory);

  return (
    <div className="relative flex flex-col items-center justify-center bg-gradient-to-r from-blue-200 to-blue-500">
      <div className="mt-4 flex flex-col justify-center sm:flex-row">
        <div className="flex flex-wrap justify-center">
          {categories.slice(0, 3).map((category) => (
            <button
              key={category}
              className={`mx-2 py-2 px-4 ${
                selectedCategory === category
                  ? "font-bold text-blue-700"
                  : "font-bold text-white"
              }`}
              onClick={() => handleCategoryChange(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap justify-center">
          {categories.slice(3).map((category) => (
            <button
              key={category}
              className={`mx-2 py-2 px-4 ${
                selectedCategory === category
                  ? "font-bold text-blue-700"
                  : "font-bold text-white"
              }`}
              onClick={() => handleCategoryChange(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      <div className="mx-auto p-5 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {filteredCards.map((card) => (
            <div
              key={card.id}
              className="rounded-xl bg-white p-5 transition duration-300"
            >
              <div className="relative">
                <img
                  className="w-full rounded"
                  src={card.image}
                  alt={card.title}
                />
              </div>
              <span className="text-sm">{card.category}</span>
              <div className="mb-2 mt-2 text-xl font-bold text-blue-800">
                {card.title}
              </div>
              <p className="text-base text-gray-700">{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Media;
