import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import TableCategories from "./components/TableCategories";
import AddCategories from "./components/AddCategories";

function Categories() {
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `/api_w/postcategories`,
        {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      setCategories(response.data.data);
    } catch (error) {
      toast.error(
        "Error fetching kategori: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  const handleTambahClick = () => {
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setShowAddPopup(false);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-end">
        <div>
          <button
            className="mr-2 rounded bg-orange-500 px-4 py-2 text-white"
            onClick={handleTambahClick}
          >
            Tambah Kategori
          </button>
        </div>
      </div>
      <TableCategories
        categories={categories}
        fetchCategories={fetchCategories}
      />
      {showAddPopup && (
        <AddCategories
          onClose={handleClosePopup}
          fetchCategories={fetchCategories}
        />
      )}
    </div>
  );
}

export default Categories;
