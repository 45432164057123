import React from "react";
import TableLogos from "./components/TableLogos";
import { useNavigate } from "react-router-dom";

function Logos() {
  const navigate = useNavigate();

  const handleTambahClick = () => {
    navigate("/admin/add-logo");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <button
            onClick={handleTambahClick}
            className="mr-2 rounded bg-[#EF9105] px-4 py-2 text-white"
          >
            Tambah Logo
          </button>
        </div>
      </div>
      <TableLogos />
    </div>
  );
}
export default Logos;
