import React from "react";
import error from "../../assets/img/404.jpg";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <img className="h-72 mx-auto mb-8" src={error} alt="404" />
        <h1 className="text-4xl font-bold mb-2 dark:text-white">Halaman Tidak Tersedia</h1>
        <p className="text-lg text-gray-600 mb-5 dark:text-white">
          Alamat yang dituju tidak tersedia.
        </p>
        <p className="text-lg text-gray-600 mb-5 dark:text-white">
          Pastikan alamat yang anda masukkan sudah benar dan coba beberapa saat
          lagi.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
