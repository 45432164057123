import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const CardFoto = ({ id, url, title, onDelete }) => {
  const navigate = useNavigate();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteFotoId, setDeleteFotoId] = useState(null);

  const handleEditClick = () => {
    navigate(`/admin/edit-foto/${id}`);
  };

  const handleDeleteClick = () => {
    setShowDeletePrompt(true);
    setDeleteFotoId(id);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api_w/galleries/${deleteFotoId}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      onDelete(deleteFotoId);
      toast.success("Berhasil Hapus Foto");
    } catch (error) {
      toast.error(
        "Error Hapus Foto: " + (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  return (
    <div className="m-2 overflow-hidden rounded bg-white shadow-lg dark:bg-gray-800">
      <img
        className="h-[150px] w-auto object-cover cursor-pointer"
        src={url}
        alt={title}
        onClick={handleEditClick}
      />
      <div className="px-6 py-4">
        <div
          className="mb-2 text-xl font-bold dark:text-white cursor-pointer"
          onClick={handleEditClick}
        >
          {title}
        </div>
        <div className="mb-2 flex items-center justify-end">
          <button
            onClick={handleDeleteClick}
            className="mr-2 transform text-red-500 hover:scale-110 hover:text-red-600"
          >
            <FaTrash className="h-4 w-4" />
          </button>
        </div>
      </div>

      {showDeletePrompt && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Foto
            </h2>
            <p className="mb-4">Apakah anda yakin akan menghapus foto ini?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="rounded bg-gray-500 py-2 px-4 text-white"
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 py-2 px-4 text-white"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardFoto;
