import React, { useEffect, useState } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import logo from "../../assets/img/logo.png";
import klaten from "../../assets/img/klaten.png";
import Dropdown from "../admin/dropdown";
import avatar from "../../assets/img/avatar4.png";
import axios from "axios";
import { toast } from "react-toastify";

const navigation = [
  { name: "Beranda", href: "/", current: false },
  { name: "Berita", href: "/berita", current: false },
  { name: "Artikel", href: "/artikel", current: false },

  {
    name: "Profil",
    href: "",
    current: false,
    submenu: [
      { name: "Visi Misi", href: "/visi-misi" },
      // { name: "Profil Sekolah" },
      { name: "Kontak", href: "/kontak" },
      { name: "Sambutan", href: "/sambutan" },
    ],
  },
  {
    name: "Galeri",
    href: "",
    current: false,
    submenu: [
      { name: "Foto", href: "/galeri-foto" },
      { name: "Video", href: "/galeri-video" },
    ],
  },
  { name: "PPDB", current: false },
  {
    name: "Fitur",
    href: "",
    current: false,
    submenu: [
      { name: "Presensi" },
      { name: "Pengajaran" },
      { name: "Data Madrasah" },
      { name: "Penilaian Evaluasi" },
      { name: "Dashboard" },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [users, setUsers] = useState([]);
  const [logos, setLogos] = useState([]);

  const updatedNavigation = navigation.map((item) => ({
    ...item,
    current: item.href === location.pathname,
  }));

  //get user by id
  useEffect(() => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");

    if (token) {
      setIsLoggedIn(true);

      if (id) {
        const fetchUsersData = async () => {
          try {
            const response = await axios.get(`/api_w/users/${id}`, {
              headers: {
                db_name: process.env.REACT_APP_DB_NAME,
              },
            });
            setUsers(response.data.data);
          } catch (error) {
            toast.error(error.response?.data?.message || error.message);
          }
        };

        fetchUsersData();
      }
    }
  }, [navigate]);

  //logout
  const handleLogout = async () => {
    try {
      await axios.delete("/api_w/users/logout", {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          db_name: process.env.REACT_APP_DB_NAME,
        },
      });
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      toast.error("Gagal Logout");
    }
  };

  //get logo id 1
  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await axios.get("/api_w/logo", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const logoData = response.data.data || [];

        // Default logo
        const defaultLogos = [{ id: 1, url: klaten }];

        // Check if the API response contains logos with ID 1
        const logosDefaults = defaultLogos.map((defaultLogo) => {
          const dataLogo = logoData.find((logo) => logo.id === defaultLogo.id);
          return dataLogo || defaultLogo;
        });

        setLogos(logosDefaults);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
        setLogos([{ id: 1, url: klaten }]);
      }
    };

    fetchLogoData();
  }, []);

  const isValidImage = (url) => {
    const validExtensions = ["jpeg", "jpg", "png", "webp", "svg"];
    const extension = url.split(".").pop().toLowerCase();
    return validExtensions.includes(extension);
  };

  const profileImage =
    users.profile_pict && isValidImage(users.profile_pict)
      ? users.profile_pict
      : avatar;

  return (
    <div>
      <Disclosure as="nav" className="bg-blue-500 fixed top-0 w-full z-50">
        {({ open }) => (
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <div className="flex sm:hidden flex-1 justify-start ml-2">
                  {logos.map((logo) => (
                    <Link to="/">
                      <img className="h-10 w-auto" src={logo.url} alt="Logo" />
                    </Link>
                  ))}
                  {/* <Link to="/">
                    <img className="h-10 w-auto" src={klaten} alt="Logo" />
                  </Link> */}
                </div>
              </div>

              <div className="hidden sm:flex flex-shrink-0 items-center">
                {logos.map((logo) => (
                  <Link to="/">
                    <img className="h-12 w-auto" src={logo.url} alt="logo" />
                  </Link>
                ))}
                {/* <Link to="/">
                  <img className="h-12 w-auto" src={klaten} alt="logo" />
                </Link> */}
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-center">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {updatedNavigation.map((item) => (
                      <div className="group relative" key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "text-white border-b-2 border-white"
                              : "text-white hover:border-b-2 hover:border-white",
                            "text-md flex items-center px-3 py-2 font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                          {item.submenu && (
                            <ChevronDownIcon className="ml-1 h-4 w-4 text-white mt-1" />
                          )}
                        </Link>
                        {item.submenu && (
                          <div className="absolute hidden bg-white shadow-lg group-hover:block">
                            {item.submenu.map((subItem) => (
                              <Link
                                key={subItem.name}
                                to={subItem.href}
                                className="text-black block px-4 py-2 text-sm hover:bg-gray-200"
                              >
                                {subItem.name}
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {isLoggedIn && (
                  <Dropdown
                    button={
                      <img
                        className="h-10 w-10 rounded-full hidden md:block"
                        src={profileImage}
                        alt={users.name}
                      />
                    }
                    children={
                      <div className="hidden md:block flex w-36 flex-col justify-start rounded-[10px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                        <div className="p-4">
                          <div className="flex items-center gap-2">
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              👋 Hey, {users.name}
                            </p>
                          </div>
                        </div>
                        <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                        <div className="flex flex-col p-4">
                          <div
                            onClick={handleLogout}
                            className="text-sm text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in cursor-pointer"
                          >
                            Logout
                          </div>
                        </div>
                      </div>
                    }
                    classNames={"py-2 top-8 -left-[120px] w-max"}
                  />
                )}
                <DisclosureButton className="sm:hidden relative inline-flex items-center justify-center rounded-md p-2 text-black hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
            </div>
            <DisclosurePanel className="sm:hidden absolute top-16 inset-x-0 bg-white z-50">
              <div className="flex flex-col space-y-2">
                {updatedNavigation.map((item) => (
                  <div className="group relative" key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "text-black border-b-2 border-blue-400"
                          : "text-black hover:border-b-2 hover:border-blue-400",
                        "text-md flex items-center px-3 py-2 font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                      {item.submenu && (
                        <ChevronDownIcon className="ml-1 h-4 w-4 text-black mt-1" />
                      )}
                    </Link>
                    {item.submenu && (
                      <div className="absolute left-20 top-full mt-2 hidden bg-white shadow-lg group-hover:block">
                        {item.submenu.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.href}
                            className="text-black block px-4 py-2 text-sm hover:bg-gray-200"
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="p-2">
                {isLoggedIn && (
                  <Dropdown
                    button={
                      <img
                        className="h-10 w-10 rounded-full sm:hidden"
                        src={profileImage}
                        alt={users.name}
                      />
                    }
                    children={
                      <div className="sm:hidden flex w-[220px] justify-end rounded-[8px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500">
                        <div className="flex flex-col p-2">
                          <div className="text-sm font-bold text-navy-700">
                            👋 Hey, {users.name}
                          </div>
                          <div
                            onClick={handleLogout}
                            className="mt-3 text-sm text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in cursor-pointer"
                          >
                            Logout
                          </div>
                        </div>
                      </div>
                    }
                    classNames={"py-2 top-8 -left-[100px] w-max"}
                  />
                )}
              </div>
            </DisclosurePanel>
          </div>
        )}
      </Disclosure>
    </div>
  );
}
