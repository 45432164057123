import React from "react";
import whatsapp from "../../../assets/icon-sosmed/wa.png";
import youtube from "../../../assets/icon-sosmed/yt.png";
import instagram from "../../../assets/icon-sosmed/ig.png";

function Sosmed() {
  return (
    <>
      {/* Desktop devices */}
      <div className="relative flex hidden items-center justify-center bg-blue-100 md:block">
        <div className="mx-auto">
          <p className="tracking-0 text-center text-3xl font-semibold italic leading-normal text-blue-600 p-8">
            Ikuti SIDIKLA di Media Sosial
          </p>
          <div className="flex flex-wrap justify-center gap-20 p-4">
            <div className="flex flex-col items-center justify-center gap-2">
              <img className="h-16 w-16" alt="whatsapp" src={whatsapp} />
              <div className="text-center text-[16px] leading-[normal] tracking-[0] text-blue-700">
                Whatsapp
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <img className="h-16 w-16" alt="youtube" src={youtube} />
              <div className="text-center text-[16px] leading-[normal] tracking-[0] text-blue-700">
                Youtube
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <img className="h-16 w-16" alt="instagram" src={instagram} />
              <div className="text-center text-[16px] leading-[normal] tracking-[0] text-blue-700">
                Instagram
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile devices */}
      <div className="relative flex flex-col items-center justify-center bg-yellow-100 md:hidden">
        <p className="text-center text-[25px] font-semibold italic leading-[normal] tracking-[0] text-yellow-700 p-10">
          Ikuti SIDIKLA di Media Sosial
        </p>
        <div className="flex flex-wrap justify-center gap-8 p-8">
          <div className="flex flex-col items-center justify-center gap-2">
            <img className="h-12 w-12" alt="whatsapp" src={whatsapp} />
            <div className="text-center text-[16px] leading-[normal] tracking-[0] text-blue-700">
              Whatsapp
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <img className="h-12 w-12" alt="youtube" src={youtube} />
            <div className="text-center text-[16px] leading-[normal] tracking-[0] text-blue-700">
              Youtube
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <img className="h-12 w-12" alt="instagram" src={instagram} />
            <div className="text-center text-[16px] leading-[normal] tracking-[0] text-blue-700">
              Instagram
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sosmed;
