import React from "react";

const ButtonSchool = ({ update, handleSubmit, handleCancel }) => {
  return (
    <div className="flex justify-end gap-4">
      <button
        type="button"
        className={
          update
            ? "rounded bg-red-500 py-2 px-3 font-bold text-white hover:bg-red-700"
            : "hidden"
        }
        onClick={handleCancel}
      >
        Batal
      </button>
      <button
        type="button"
        className={
          update
            ? "rounded bg-orange-500 py-2 px-3 font-bold text-white hover:bg-orange-700"
            : "hidden"
        }
        onClick={handleSubmit}
      >
        Simpan
      </button>
    </div>
  );
};

export default ButtonSchool;
