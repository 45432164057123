import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CardFoto from "./components/CardFoto";

const Foto = () => {
  const navigate = useNavigate();
  const [foto, setFoto] = useState([]);

  const handleTambahClick = () => {
    navigate("/admin/add-foto");
  };

  useEffect(() => {
    const fetchFotoData = async () => {
      try {
        const response = await axios.get(`/api_w/galleries`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setFoto(response.data.data);
      } catch (error) {
        toast.error(
          "Error fetching foto data" +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchFotoData();
  }, []);

  const filteredFoto = foto.filter((item) => item.type === "image");

  const handleDelete = (deletedId) => {
    setFoto((prevFoto) => prevFoto.filter((item) => item.id !== deletedId));
  };

  return (
    <div className="relative flex flex-wrap justify-center">
      <button
        onClick={handleTambahClick}
        className="absolute left-4 top-4 rounded bg-orange-500 px-4 py-2 text-white hover:bg-orange-400"
      >
        Tambah Foto
      </button>
      <div className="mt-16 flex w-full flex-wrap justify-center">
        {filteredFoto.map((item) => (
          <CardFoto
            key={item.id}
            id={item.id}
            url={item.url.split(",")[0]}
            type={item.type}
            title={item.title}
            onDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
};
export default Foto;
