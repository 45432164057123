import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CardVideo from "./components/CardVideo";

const Video = () => {
  const navigate = useNavigate();
  const [video, setVideo] = useState([]);

  const handleTambahClick = () => {
    navigate("/admin/add-video");
  };

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`/api_w/galleries`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        setVideo(response.data.data);
      } catch (error) {
        toast.error(
          "Error fetching video data" +
            (error.response?.data?.message || error.message)
        );
      }
    };

    fetchVideoData();
  }, []);

  const handleDelete = (deletedId) => {
    setVideo((prevVideo) => prevVideo.filter((item) => item.id !== deletedId));
  };

  const filteredVideo = video.filter((item) => item.type === "video");

  return (
    <div className="relative flex flex-wrap justify-center">
      <button
        onClick={handleTambahClick}
        className="absolute left-4 top-4 rounded bg-orange-500 hover:bg-orange-500 px-4 py-2 text-white"
      >
        Tambah Video
      </button>
      <div className="mt-16 flex w-full flex-wrap justify-center">
        {filteredVideo.map((item) => (
          <CardVideo
            key={item.id}
            id={item.id}
            url={item.url}
            type={item.type}
            title={item.title}
            onDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
};

export default Video;
