import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegCalendarAlt, FaRegClock, FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

function CardBerita() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const response = await axios.get(`/api_w/posts?`, {
          params: {
            // status: 1,
            category_id: 1,
          },
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const filteredPosts = response.data.data.filter(
          (post) => post.status == 1
        );

        setCards(filteredPosts);
      } catch (error) {
        toast.error("Error fetching data", error);
      }
    };

    fetchPostsData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const formatTime = (timeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(timeString).toLocaleTimeString("id-ID", options);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="p-4">
      <div className="mb-8 flex items-center justify-between">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          <h5 className="name-section text-[18px] font-bold">Berita</h5>
        </span>
        <div className="flex items-center">
          <Link
            to="/berita"
            className="text-gray-400 no-underline hover:cursor-pointer hover:text-blue-600"
          >
            Lihat selengkapnya
          </Link>
        </div>
      </div>
      <div className="flex flex-wrap">
        {cards.slice(0, 1).map((berita, index) => (
          <div key={index} className="mb-2 w-full">
            <div className="card-berita">
              <div className="card mb-4">
                <div className="flex flex-wrap shadow-lg rounded-xl">
                  <div className="w-full p-2 md:w-1/3">
                    <div className="card-body flex items-center justify-center">
                      <img
                        src={berita.image}
                        className="h-auto w-auto transform transition-transform duration-300 hover:scale-110 rounded-md"
                        alt={berita.title}
                      />
                    </div>
                  </div>
                  <div className="w-full p-2 md:w-2/3">
                    <div className="card-body">
                      <div className="mb-2 flex items-center space-x-4 text-sm">
                        <div className="flex items-center">
                          <FaRegCalendarAlt className="mr-2 h-4 w-4 text-blue-500" />
                          {formatDate(berita.date)}
                        </div>
                        <div className="flex items-center">
                          <FaRegClock className="mr-2 h-4 w-4 text-blue-500" />
                          {formatTime(berita.date)} WIB
                        </div>
                        {/* <div className="flex items-center">
                          <FaEye className="mr-2 h-4 w-4 text-blue-500" />
                          {berita.views}
                        </div> */}
                      </div>

                      <Link to={`/berita/${berita.slug}`}>
                        <h5 className="card-title-berita text-[20px] font-bold hover:cursor-pointer hover:text-blue-700">
                          {truncateText(berita.title, 120)}
                        </h5>
                      </Link>
                      <p className="card-text-berita mt-4 text-justify">
                        {parse(truncateText(berita.description, 400))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div className=" flex justify-center">
        <Link
          to="/berita"
          className="flex items-center rounded-[10px] bg-blue-600 px-3 py-2 text-white hover:bg-blue-700"
        >
          Lihat Selengkapnya
          <IoMdArrowRoundForward className="ml-1 mt-1" />
        </Link>
      </div> */}
    </div>
  );
}

export default CardBerita;
