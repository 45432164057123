import React from "react";
import Header from "../../../components/user/Header";
import Footer from "../../../components/user/Footer";
import BeritaPopuler from "../../../components/user/posts/BeritaPopuler";
import banner from "../../../assets/img/banner1.png";
import CardVisiMisi from "../../../components/user/visi-misi/CardVisiMisi";

function VisiMisi() {
  return (
    <>
      <Header />
      <div className="relative top-[35px] mt-[28px] inline-flex h-auto w-full">
        <img className="banner h-auto w-full" src={banner} alt="banner" />
        <div className="max-w-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform font-bold text-white text-center">
          <p className="md:text-4xl text-xl">
            Visi Misi <br /> SIDIKLA
          </p>
        </div>
      </div>
      <div className="row mt-10 flex flex-wrap gap-6 p-5">
        <div className="w-full lg:w-[68%]">
          <CardVisiMisi />
        </div>

        <div className="w-full lg:ml-auto lg:w-[30%]">
          <BeritaPopuler />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VisiMisi;
