import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AddCategories = ({ onClose, fetchCategories }) => {
  const [formData, setFormData] = useState({
    name: "",
  });
  const popupRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api_w/postcategories`,
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        db_name: process.env.REACT_APP_DB_NAME,
      },
      data: JSON.stringify(formData),
    };

    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      toast.success("Berhasil Tambah Kategori!");
      fetchCategories();
      onClose();
    } catch (error) {
      toast.error(
        "Error Tambah Kategori: " +
          (error.response?.data?.message || error.message)
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
      <form
        ref={popupRef}
        onSubmit={handleSubmit}
        className="w-full max-w-md rounded bg-white p-4 shadow-md"
      >
        <div className="mb-4">
          <label className="text-md block font-medium font-bold text-gray-800">
            Nama Kategori
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="block w-full rounded-md border-2 border-gray-300 px-3 py-2 shadow-sm outline-none"
            required
          />
        </div>
        <div className="flex justify-end gap-4">
          <button
            type="button"
            className="rounded bg-red-500 py-2 px-4 font-bold text-white"
            onClick={onClose}
          >
            Batal
          </button>
          <button
            type="submit"
            className="rounded bg-orange-500 py-2 px-4 font-bold text-white"
          >
            Tambah
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCategories;
