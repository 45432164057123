import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import banner from "../../../../assets/img/banner1.png";
import Header from "../../../../components/user/Header";
import Footer from "../../../../components/user/Footer";
import BeritaPopuler from "../../../../components/user/posts/BeritaPopuler";
import { Gallery } from "react-grid-gallery";
import { IoMdCamera } from "react-icons/io";
import DetailsFoto from "../../../../components/user/galeri/foto/DetailsFoto";

function Foto() {
  const [images, setImages] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get("/api_w/galleries?type=image", {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const formattedImages = response.data.data.map((image) => ({
          src: image.url.split(",")[0],
          thumbnail: image.url.split(",")[0],
          width: 320,
          height: 212,
          caption: image.title,
          thumbnailCaption: (
            <div
              onClick={() => setSelectedPhoto(image)}
              className="flex justify-center items-center bg-blue-600 opacity-75 text-white p-1 font-bold text-sm cursor-pointer rounded-b-md"
            >
              {truncateText(image.title, 32)}
            </div>
          ),
          customOverlay: (
            <div className="custom-overlay absolute inset-0 flex justify-center items-center">
              <button
                onClick={() => setSelectedPhoto(image)}
                className="bg-white opacity-75 p-4 rounded-full shadow-lg hover:bg-blue-100 transition-colors duration-300"
              >
                <IoMdCamera className="h-7 w-7 text-blue-600" />
              </button>
            </div>
          ),
        }));

        setImages(formattedImages);
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchImages();
  }, []);

  const handleCloseDetails = () => {
    setSelectedPhoto(null);
  };

  return (
    <>
      <Header />
      <div className="relative top-[35px] mt-[28px] inline-flex h-auto w-full">
        <img className="banner h-auto w-full" src={banner} alt="banner" />
        <div className="max-w-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform font-bold text-white text-center">
          <p className="md:text-4xl text-2xl">
            Galeri Foto <br /> SIDIKLA KLATEN
          </p>
        </div>
      </div>

      <div className="row mt-10 flex flex-wrap gap-6 p-4">
        <div className="w-full lg:w-[68%]">
          <Gallery
            images={images}
            enableImageSelection={false}
            backdropClosesModal={true}
            margin={8}
          />
        </div>

        <div className="w-full lg:ml-auto lg:w-[30%]">
          <BeritaPopuler />
        </div>
      </div>
      <Footer />
      {selectedPhoto && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex items-center justify-center z-50">
          <DetailsFoto photo={selectedPhoto} onClose={handleCloseDetails} />
        </div>
      )}
    </>
  );
}

export default Foto;
