import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import logo from "../../../assets/img/klaten.png";
import axios from "axios";
import { toast } from "react-toastify";

function Sambutan() {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const fetchDataSambutan = async () => {
      try {
        const response = await axios.get(`/api_w/abouts?title=sambutan`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const sambutans = response.data.data[0];

        if (sambutans) {
          setTitle(sambutans.title);
          setBody(sambutans.body);
          setType(sambutans.type || "image");
          setImageUrl(sambutans.image_url);
          setVideoUrl(sambutans.video_url);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchDataSambutan();
  }, []);

  const renderVideo = (url) => {
    const youtubeMatch = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    const gdriveMatch = url.match(/drive\.google\.com\/file\/d\/([^\/]+)\//);

    let videoUrl = url;
    if (youtubeMatch) {
      videoUrl = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (gdriveMatch) {
      videoUrl = `https://drive.google.com/file/d/${gdriveMatch[1]}/preview`;
    }
    return (
      <div className="flex justify-center">
        <iframe
          className="w-80 h-48 rounded-md"
          src={videoUrl}
          title="Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  const renderContent = () => {
    if (type === "image") {
      return imageUrl ? (
        <img
          src={imageUrl}
          alt={title || "Sambutan"}
          className="h-[250px] w-auto rounded-md"
        />
      ) : (
        <img src={logo} alt="Logo" className="h-[250px] w-auto rounded-md" />
      );
    } else if (type === "video") {
      return videoUrl ? (
        renderVideo(videoUrl)
      ) : (
        <img src={logo} alt="Logo" className="h-[250px] w-auto rounded-md" />
      );
    } else {
      return <img src={logo} alt="Logo" className="h-[250px] w-auto rounded-md" />;
    }
  };

  return (
    <div className="mb-8 p-4">
      <h3 className="headers text-2xl">
        <span className="border-b-2 border-blue-500 font-bold hover:cursor-pointer hover:text-blue-700">
          SIDIKLA...
        </span>
      </h3>
      <div className="card mt-4 flex flex-col border-2 px-3 py-3 shadow-md md:flex-row bg-blue-500">
        <div className="md:mr-4">
          <div className="flex items-center justify-center text-center">
            {renderContent()}
          </div>
          {/* <div className="mt-2">
            <p className="flex items-center justify-center font-bold">{name}</p>
            <p className="flex items-center justify-center">{position}</p>
          </div> */}
        </div>
        <div className="flex flex-1 flex-col justify-between overflow-hidden ">
          <div className="mt-4 w-[225px] break-words text-justify md:mt-0 md:w-auto text-white">
            {parse(body || "Selamat datang di website SIDIKLA")}
          </div>
          <div className="mt-4 flex items-end justify-end">
            <div className="text-left">
              <p className="mt-2 font-bold text-white">Sistem Informasi Digital Pelajar Klaten</p>
              <br />
              <br />
              <br />
              {/* <p className="font-bold">{name}</p>
              <p>{position}</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sambutan;
